<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="filterByPortAuthorityId"
		></pui-datatable>
	</div>
</template>

<script>
import companyActions from './CompanyActions';

export default {
	name: 'company-grid',
	components: {},
	data() {
		return {
			modelName: 'company',
			actions: companyActions.gridactions,
			modelColumnDefs: {}
		};
	},
	computed: {
		filterByPortAuthorityId() {
			const workingPortAuthorityId = window.localStorage.getItem('workingPortAuthorityId');
			let groupPa = {
				groups: [],
				groupOp: 'or',
				rules: [
					{ field: 'port_authority_id', op: 'eq', data: workingPortAuthorityId },
					{ field: 'port_authority_id', op: 'nu' }
				]
			};

			return {
				groups: [groupPa],
				groupOp: 'and',
				rules: [{ field: 'companytypeid', op: 'ne', data: 10 }]
			};
		}
	}
};
</script>
